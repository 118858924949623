/* eslint-disable eqeqeq */
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { USPSNavBar } from "../common/components/nav-bar/USPSNavBar";
import { PageNotFound } from "../common/components/PageNotFound";
import { ScrollToTop } from "../common/components/ScrollToTop";
import { USPSGlobalFooter } from "../common/components/SMARTLOCKER-footer";
import { USPSGlobalHeader } from "../common/components/SMARTLOCKER-header";
import { tradeShowCheck } from "../common/utils";
import {
   BUSINESS_PAGE_URL,
   CONTEST_CLOSED_URL,
   CUSTOMER_PAGE_URL,
   FAQ_PAGE_URL,
   LOCKER_LOCATION_PAGE_URL,
   MERCHANT_PAGE_URL,
   NPF_TC_URL,
   NPF_URL,
} from "../config/pages";
import Business from "../features/business/Business";
import Console from "../features/console/Console";
import ContestClosed from "../features/contest-page/ContestClosed";
import ContestPage from "../features/contest-page/ContestPage";
import NPFCartPage from "../features/contest-page/NPFCartPage";
import NPFContestPage from "../features/contest-page/NPFContestPage";
import NPFThankYou from "../features/contest-page/NPFThankYou";
import { TermsConditions } from "../features/contest-page/TermsConditions";
import PackageRecipients from "../features/customer/PackageRecipients";
import Faq from "../features/faq/Faq";
import Landing from "../features/landing/Landing";
import CSVLockerLookup from "../features/locker-lookup/CSVLockerLookup";
import { LockerLookup } from "../features/locker-lookup/LockerLookup";
import CurrentAndPastReservations from "../features/locker-reservation/CurrentAndPastReservations";
import GuestReserve from "../features/locker-reservation/GuestReserve";
import GuestReserveCode from "../features/locker-reservation/GuestReserveCode";
import Login from "../features/login/Login";
import { PackageShippers } from "../features/merchant/PackageShippers";
import { Api } from "../services/Api";
import AuthContext from "../services/AuthContext";
import AuthService from "../services/AuthService";

const ProtectedRouteProps = {
   isAllowed: PropTypes.bool,
   redirectPath: PropTypes.string,
   children: PropTypes.node,
};

const ProtectedRoute = ({ isAllowed, redirectPath = "/login", children }) => {
   if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
   }

   return children || <Outlet />;
};

ProtectedRoute.propTypes = ProtectedRouteProps;

const App = () => {
   const queryString = window.location.search;
   const api = new Api();
   api.setup();

   const authService = new AuthService();
   const [isLoggedIn, setIsLoggedIn] = useState(authService.isLoggedIn());
   const [tradeShow, setTradeShow] = useState();
   const [csvFlag, setCSVFlag] = useState();
   const [psFlag, setPSFlag] = useState();
   const [lockerList, setLockerList] = useState();
   const [contestLink, setContestLink] = useState(false);
   const [loading, setLoading] = useState(true);

   const location = useLocation();
   const content = useRef(null);
   const navbar = useRef(null);

   const handleLogout = () => {
      localStorage.setItem("isLoggedIn", "false");
      setIsLoggedIn(false);
   };

   const authContextLogin = useMemo(() => ({ isLoggedIn, handleLogout }), [isLoggedIn]);

   const getAllTradeShows = useCallback(async () => {
      return await api
         .getAllTradeShows()
         .then(response => tradeShowCheck(response.data, "show-id", Number(localStorage.getItem("show_id"))));
   }, []);

   const getFeatureFlag = useCallback(async featureFlag => {
      return await api.getFeatureFlag(featureFlag);
   }, []);

   const getCurrentLockerList = useCallback(async () => {
      return await api.getCurrentLockerList();
   }, []);

   useEffect(() => {
      (async () => {
         setTradeShow(await getAllTradeShows());
         // Look for whether new CSV search is being used or not
         const csvResponse = await getFeatureFlag("csv-search");
         setCSVFlag(csvResponse.data.response);
         const psResponse = await getFeatureFlag("hide-ps");
         setPSFlag(psResponse.data.response);
         setLockerList(await getCurrentLockerList());
         setLoading(false);
      })();
   }, [getAllTradeShows, getFeatureFlag, getCurrentLockerList]);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const resizeContent = useCallback(() => {
      if (!content.current || !navbar.current) return;
      content.current.style.marginTop = `${navbar.current.getBoundingClientRect().height}px`;
   });

   useEffect(() => {
      resizeContent();
   }, [resizeContent]);

   useEffect(() => {
      window.addEventListener("resize", resizeContent);
      return () => window.removeEventListener("resize", resizeContent);
   }, [resizeContent]);

   useEffect(() => {
      if (location.pathname !== "/authentication/callback") {
         ReactGA.send({ hitType: "pageview", page: location.pathname });
      }
   }, [location]);

   useEffect(() => {
      if (queryString) {
         const urlParams = new URLSearchParams(queryString);

         localStorage.setItem("show_id", urlParams.get("show_id"));
         setContestLink(true);
      }

      if (localStorage.getItem("show_id") && !contestLink) setContestLink(true);
   }, [contestLink, queryString]);

   const reload = () => window.location.reload();

   const [ignore, setIgnore] = useState(false);

   const logAnalytics = async () => {
      return await api.postLogAnalytics();
   };

   useEffect(() => {
      if (!ignore) {
         logAnalytics();
         setIgnore(true);
      }
   }, [ignore]);

   if (!loading) {
      return (
         <ScrollToTop>
            <AuthContext.Provider value={authContextLogin}>
               <Routes>
                  <Route element={<Outlet />}>
                     <Route exact path="/robots.txt" onEnter={reload} />
                  </Route>
                  <Route
                     element={
                        <>
                           <USPSGlobalHeader tradeShow={tradeShow} psFlag={psFlag} />
                           <USPSNavBar ref={navbar} tradeShow={tradeShow} psFlag={psFlag} />
                           <Outlet />
                           <USPSGlobalFooter />
                        </>
                     }
                  >
                     <Route path="/" element={<Landing />} />
                     {psFlag === "true" ? <></> : <Route path={MERCHANT_PAGE_URL} element={<PackageShippers />} />}
                     <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />} />
                     <Route path={CUSTOMER_PAGE_URL} element={<PackageRecipients />} />
                     <Route path={BUSINESS_PAGE_URL} element={<Business />} />
                     <Route
                        path={LOCKER_LOCATION_PAGE_URL}
                        element={csvFlag === "true" ? <CSVLockerLookup lockerList={lockerList} /> : <LockerLookup />}
                     />
                     <Route
                        path="/console"
                        element={
                           <ProtectedRoute isAllowed={isLoggedIn}>
                              <Console />
                           </ProtectedRoute>
                        }
                     />
                     {tradeShow && tradeShow.length !== 0 && (
                        <>
                           <Route path="/contest-page/:uuid?" element={<ContestPage tradeShow={tradeShow} />} />
                           <Route path={NPF_URL}>
                              <Route path="" element={<NPFContestPage tradeShow={tradeShow} />} />
                              <Route path="cart" element={<NPFCartPage tradeShow={tradeShow} />} />
                              <Route path="thank-you" element={<NPFThankYou tradeShow={tradeShow} />} />
                           </Route>
                        </>
                     )}
                     <Route path={NPF_TC_URL} element={<TermsConditions tradeShow={tradeShow} />} />
                     <Route path={FAQ_PAGE_URL} element={<Faq />} />
                     <Route path={CONTEST_CLOSED_URL} element={<ContestClosed />} />
                     {process.env.REACT_APP_GUEST_RESERVATION === "true" && (
                        <Route path="guest-reservation">
                           <Route path="" element={<PageNotFound />} />
                           <Route path="email" element={<GuestReserve />} />
                           <Route path="code" element={<GuestReserveCode />} />
                           <Route path="success" element={<CurrentAndPastReservations />} />
                           <Route path="*" element={<PageNotFound />} />
                        </Route>
                     )}
                     <Route path="*" element={<PageNotFound />} />
                  </Route>
               </Routes>
            </AuthContext.Provider>
         </ScrollToTop>
      );
   }
};

export default App;
